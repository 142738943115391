<template>
  <div class="content">
    <div v-html="text"></div>

    <div style="margin-top: 30px" @click="handleClick" class="button">
      <img src="" alt="" /> 立即咨询专家
    </div>

    <el-dialog title="扫码" :visible.sync="dialogVisible" width="30%">
      <div class="f-col" style="align-items: center">
        <!-- <img :src="Img.records[6]" alt="" v-if="Img.records.length > 0" /> -->
        <!-- <div v-html="Img.records[6].content"></div> -->

        <img
          src="../../../assets/find/mini_code.jpg"
          alt=""
          style="width: 150px"
        />
        <span style="margin-top:10px">请使用微信扫一扫前往OK志愿查看</span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getContentInfo } from "@/api";
export default {
  name: "oneServe",
  data() {
    return {
      dialogVisible: false,
      Img: {
        records: [],
      },
      text: "",
    };
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    //   路由跳转
    handleClick() {
      this.dialogVisible = true;
      this.getImg();
    },
    async getImg() {
      let res = await pageAdvertisementInfo({ type: 2 });
      if (res.code == 1) {
        this.Img = res.data;
      }
    },
    async getDetail() {
      let res = await getContentInfo({ contentType: 1, type: 3 });
      if (res.code == 1) {
        this.text = res.data.content;
      }
    },
  },
};
</script>
<style lang="less" scoped>
@mycolor: #1ab394;
.content {
  text-align: center;
  .button {
    cursor: pointer;
    border-radius: 25px;
    //   width: 240px;
    background: @mycolor;
    color: white;
    display: inline-block;
    font-size: 30px;
    padding: 5px 30px;
  }
}
</style>